<template>

  <div :class="'mes' + (type ? ' ' + type : '')"> 

    <i v-if="icon" :class="icon"></i>
    <div class="text">
      <slot></slot>
    </div>
    
    <UiButton
      v-if="buttonName"
      :name="buttonName"
      :type="buttonType"
      @click="emits('click-button')"
      />

  </div>
  
</template>


<script setup>

const emits = defineEmits(['click-button']);

const props = defineProps({
  icon: {
    type: String
  }, 
  type: {
    type: String,
    default: ''
  },
  buttonName: {
    type: String,
    default: ''
  },
  buttonType: {
    type: String,
    default: ''
  },
});

</script>
 

 
<style scoped>

.mes{    
  background-color: #f6f6f6;
  box-shadow: 0px 0px 0px 1px #cdcdcd inset, 0px 0px 0px 0px rgb(0 0 0 / 0%);
  padding: 1rem;
  border-radius: 5px;
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
  gap: 1rem;
}

.orange{    
  background-color: #FFF8DB;
  color: #B58105;
  box-shadow: 0px 0px 0px 1px #b58105 inset, 0px 0px 0px 0px rgb(0 0 0 / 0%);
}

.green{    
  background-color: #00895717;
  color: #008957;
  box-shadow: 0px 0px 0px 1px #008957a1 inset, 0px 0px 0px 0px rgb(0 0 0 / 0%);
}
  
.mes .icon{
  flex-shrink: 0;
}
.mes .text{
  margin-right: auto;
}




</style>